import React, { Component, Button, Text } from "react";
import { withTranslation } from 'react-i18next';
import i18n from 'i18next';

export class Navigation extends Component {
  render() {
    const { t } = this.props;
    return (
      <nav style={{ minWidth: 450 }} id="menu" className="navbar navbar-default navbar-fixed-top">
        <div className="container">
          <div style={{ flex: '', flexDirection: 'row-reverse' }}>
            <div style={{ float: i18n.dir() === 'rtl' ? 'right' : 'left' }} className="navbar-header">
              <button
                type="button"
                className="navbar-toggle collapsed"
                data-toggle="collapse"
                data-target="#bs-example-navbar-collapse-1"
              >
                {" "}
                <span className="sr-only">Toggle navigation</span>{" "}
                <span className="icon-bar"></span>{" "}
                <span className="icon-bar"></span>{" "}
                <span className="icon-bar"></span>{" "}
              </button>
              <a className="navbar-brand page-scroll" href="#page-top">
                <img src="/img/logo_long.png" style={{width: 100, height: 30}} />
            </a>{" "}
            </div>

            <div
              className="collapse navbar-collapse"
              id="bs-example-navbar-collapse-1"
            >

              {i18n.dir() === 'ltr' ? <ul style={{ float: i18n.dir() === 'rtl' ? 'left' : 'right' }} className="nav navbar-nav navbar">
                <li>
                  <a href="#contact" className="page-scroll">
                    {t('Navigation.contact')}
                  </a>
                </li>
                <li>
                  <a href="" onClick={() => { i18n.dir() === 'rtl' ? i18n.changeLanguage('en') : i18n.changeLanguage('ar') }}>{t('lang')}</a>
                </li>
              </ul>
                :
                <ul style={{ float: i18n.dir() === 'rtl' ? 'left' : 'right' }} className="nav navbar-nav navbar">
                  <li>
                    <a href="" onClick={() => { i18n.dir() === 'rtl' ? i18n.changeLanguage('en') : i18n.changeLanguage('ar') }}>{t('lang')}</a>
                  </li>
                  <li>
                    <a href="#contact" className="page-scroll">
                      {t('Navigation.contact')}
                    </a>
                  </li>
                </ul>
              }

            </div>
          </div>
        </div>
      </nav >
    );
  }
}

export default withTranslation()(Navigation);
