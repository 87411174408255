import React, { Component } from 'react'
import Navigation from './components/navigation';
import Header from './components/header';
import Contact from './components/contact';
import About from './components/about';
import Canvas from './components/Canvas.tsx';

export class App extends Component {
  state = {
    landingPageData: {},
  }

  render() {
    return (
      <div>
        <Navigation />
        <div style={{ width: "100%" }}>  <Header /></div>
        <About />
        <Canvas />
        <Contact />
      </div>
    )
  }
}

export default App;
