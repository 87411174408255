import React, { Component } from 'react'
import { withTranslation } from 'react-i18next';
import i18n from 'i18next';

export class about extends Component {
  state = { width: window.innerWidth };

  updateDimensions = () => {
    this.setState({ width: window.innerWidth});
  };
  componentDidMount() {
    window.addEventListener('resize', this.updateDimensions);
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }

  render() {
    const { t } = this.props;
    return (
      <div style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'column', alignSelf: 'center' }} id="about">
        <div className="container" >
          <div dir={i18n.dir()} style={{justifyContent: 'center',alignContent: "center", alignItems: 'center', textAlign: 'center', display: this.state.width > 900 ? "flex" : "block"}}>
            <div style={{flex: 1}}> <img src="img/about.png" style={{ borderRadius: 50, width: "100%", maxHeight: 300 }} className="img-responsive" alt="" /> </div>
            <div style={{flex: 1}}>
              <div className="about-text">
                <h2> {t('About.message')}</h2>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default withTranslation()(about);
