import React, { Component } from "react";
import { withTranslation } from 'react-i18next';

export class Header extends Component {

  render() {
    const { t } = this.props;
    return (
      <header id="header">
        <div className="intro">
          <div className="overlay">
            <div className="container">
              <div className="row">
                <div className="intro-text">
                  <h1>
                    {t('Header.title')}
                    <span></span>
                  </h1>
                  <p>
                    {t('Header.paragraph')}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    );
  }
}

export default withTranslation()(Header);
